<!-- Modals -->
<app-common-bulk-select-custom-field-dialog
    #selectCustomFields
    (filtersAdded)="customFieldsFiltersAdded()"
></app-common-bulk-select-custom-field-dialog>
<app-common-bulk-select-onboarding-reminder-dialog #selectReminders></app-common-bulk-select-onboarding-reminder-dialog>
<app-common-select-work-schedule-dialog #selectWorkSchedule></app-common-select-work-schedule-dialog>
<app-common-bulk-select-role-dialog #selectRoles></app-common-bulk-select-role-dialog>
<app-common-bulk-select-training-programs #selectTrainingPrograms></app-common-bulk-select-training-programs>
<app-common-select-benefit-plan-dialog #selectBenefitPlan></app-common-select-benefit-plan-dialog>

<!-- Temporarily disabled -->
<app-select-new-employee-benefits-dialog
    *ngIf="companyHasSimplyBenefits"
    [open]="showBenefitsDialog"
    [clearDataBeforeOpen]="clearSimplyBenefitsDataBeforeOpen"
    (closing)="showBenefitsDialog = false"
    (benefitData)="onBenefitDataChange($event)"
></app-select-new-employee-benefits-dialog>

<app-deprecated-modal-form
    [form]="selectTimeOffPoliciesForm"
    #selectTimeOffPolicies
    (save)="onSaveTimeOffPolicies()"
    headerText="forms.applicant-tracker.onboarding.selectTimeOffPolicies"
>
    <app-form-onboarding-time-off-policies
        #selectTimeOffPoliciesForm
        [employee]="employee"
        [policies]="timeOffPolicies"
    ></app-form-onboarding-time-off-policies>
</app-deprecated-modal-form>

<!-- Create new task template modal -->
<ui-dialog
    size="small"
    [open]="showCreateTaskTemplateModal"
    (closing)="showCreateTaskTemplateModal = false"
    header="forms.applicant-tracker.onboarding.addOnboardingTasks"
>
    <div class="create-task-template-content">
        <p>{{ 'forms.applicant-tracker.onboarding.noOnboardingTaskTemplatesExist' | appTranslate }}</p>
        <p style="margin-bottom: 30px">
            {{ 'forms.applicant-tracker.onboarding.clickTheButtonBelowToCreateOneNowLinkOpensInNewTab' | appTranslate }}
        </p>

        <ui-button type="outline" url="/employees/task-templates/new" [openInNewTab]="true"
            >{{ 'forms.applicant-tracker.onboarding.createTaskTemplate' | appTranslate }}
            <mat-icon svgIcon="openInNew"></mat-icon>
        </ui-button>
    </div>
</ui-dialog>

<!-- Time Tracking Settings Confirmation -->
<ui-confirmation-dialog
    #timeTrackingSettingsConfirmation
    header="time-tracking.hiring.settingsHeader"
    primaryButtonLabel="save"
    secondaryButtonLabel="cancel"
>
    <p>{{ 'time-tracking.hiring.explanation' | appTranslate }}</p>

    <div class="margin-top-4">
        <mat-checkbox [(ngModel)]="selectedAddToTimeTracking">
            {{ 'time-tracking.hiring.addToTimeTracking' | appTranslate }}</mat-checkbox
        >
    </div>

    <div class="margin-top-2" *ngIf="selectedAddToTimeTracking">
        <mat-form-field class="width-full">
            <mat-label appRemoveAriaOwns>{{ 'time-tracking.hiring.selectProjectsIfNeeded' | appTranslate }}:</mat-label>
            <mat-select
                multiple
                [(ngModel)]="selectedProjects"
                [compareWith]="compareProjects"
                [placeholder]="
                    (allProjects.length ? 'time-tracking.hiring.selectProjects' : 'time-tracking.hiring.noProjects')
                        | appTranslate
                "
            >
                <ng-container *ngIf="allProjects.length">
                    <!-- Please see the comment on the onSelectAllProjects method to understand what's happening here. -->
                    <mat-checkbox
                        class="mat-option option-checkbox"
                        [indeterminate]="
                            selectedProjects.length !== 0 && selectedProjects.length !== allProjects.length
                        "
                        [checked]="selectedProjects.length === allProjects.length"
                        (change)="onSelectAllProjects()"
                    >
                        {{ 'time-tracking.hiring.selectAllProjects' | appTranslate }}
                    </mat-checkbox>
                    <mat-option *ngFor="let project of allProjects" [value]="project">{{ project.name }}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <p>
            <mat-icon svgIcon="info"></mat-icon>
            {{ 'time-tracking.hiring.youCanAssignLater' | appTranslate }}
        </p>

        <!-- only show if company has integration turned on and the new employee is going to be on payroll -->
        <ng-container *ngIf="companyIsOnTimeTrackingPayrollIntegration && employee.isPayrollSyncEnabled">
            <div class="margin-top-2 margin-bottom-2">
                <mat-checkbox
                    name="add-to-time-tracking-payroll-integration"
                    [(ngModel)]="selectedAddToTimeTrackingPayrollIntegration"
                >
                    {{ 'time-tracking.hiring.transferHoursToPayroll' | appTranslate }}
                </mat-checkbox>
            </div>

            <ui-tip class="margin-bottom-3">{{ 'time-tracking.hiring.payrollIntegrationTip' | translate }}</ui-tip>
        </ng-container>
    </div>
</ui-confirmation-dialog>

<!-- Apply tasks modal -->
<app-deprecated-modal-form
    #applyTasksModal
    [form]="applyTasksForm"
    [label]="'apply'"
    (save)="onApplyTaskTemplates(applyTasksForm.getSelectedTaskTemplates())"
    headerText="forms.applicant-tracker.onboarding.addOnboardingTasks"
>
    <app-deprecated-apply-tasks
        #applyTasksForm
        [showEmptyState]="true"
        [employee]="employee"
        [autoMatchedTaskTemplates]="autoMatchedTaskTemplates"
        [otherTaskTemplates]="otherTaskTemplates"
    ></app-deprecated-apply-tasks>
</app-deprecated-modal-form>

<!-- Form -->
<form class="ui form" #form="ngForm" [class.loading]="isLoading">
    <!-- Custom Fields -->
    <div class="section">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.applicant-tracker.onboarding.customFields' | appTranslate }}
                <div class="sub header">
                    {{
                        'forms.applicant-tracker.onboarding.applyCustomFieldsToYourEmployeeForThemToFillOutWhenTheyCreateThe'
                            | appTranslate
                    }}
                </div>
            </h3>
            <ui-button (click)="onSelectCustomFields()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="customFields.length" class="item-list">
            <app-common-label-item
                *ngFor="let cField of customFields"
                (remove)="onRemoveCustomField(cField)"
                [label]="cField.name"
            >
            </app-common-label-item>
        </div>
    </div>

    <!-- Onboarding Tasks -->
    <ng-container *ngIf="showOnboardingTasks">
        <div class="section">
            <div class="row">
                <h3 class="ui header">
                    {{ 'forms.applicant-tracker.onboarding.onboardingTasks' | appTranslate }}
                    <div class="sub header">
                        {{
                            'forms.applicant-tracker.onboarding.selectTasksForThisEmployeesOnboardingToBeCompletedByThemOrOtherE'
                                | appTranslate
                        }}
                    </div>
                </h3>
                <ui-button (click)="onAddTaskTemplate()" type="outline">
                    <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
                </ui-button>
            </div>

            <!-- List -->
            <div *ngIf="taskTemplates?.length" class="item-list">
                <app-common-label-item
                    *ngFor="let taskTemplate of taskTemplates"
                    (remove)="onRemoveTaskTemplate(taskTemplate)"
                    [label]="taskTemplate.name"
                >
                </app-common-label-item>
            </div>
        </div>
    </ng-container>

    <!-- Reminders -->
    <div class="section">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.applicant-tracker.onboarding.reminders' | appTranslate }}
                <div class="sub header">
                    {{
                        'forms.applicant-tracker.onboarding.selectOnboardingRemindersThatShouldBeTriggeredByThisHire'
                            | appTranslate
                    }}
                </div>
            </h3>
            <ui-button (click)="onSelectOnboardingReminders()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="reminders.length" class="item-list">
            <app-common-label-item
                *ngFor="let reminder of reminders"
                (remove)="onRemoveOnboardingReminder(reminder)"
                [label]="reminder.name"
            >
            </app-common-label-item>
        </div>
    </div>

    <!-- Work Schedules -->
    <div class="section" *ngIf="showWorkSchedules">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.applicant-tracker.onboarding.timeOffWorkSchedule' | appTranslate }}
                <div class="sub header">
                    {{ 'forms.applicant-tracker.onboarding.assignATimeOffWorkScheduleToThisEmployee' | appTranslate }}
                </div>
            </h3>
            <ui-button (click)="onSelectWorkSchedule()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="workSchedule" class="item-list">
            <app-common-label-item (remove)="onRemoveWorkSchedule()" [label]="workSchedule.name">
            </app-common-label-item>
        </div>
    </div>

    <!-- Time Off Policies -->
    <div class="section">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.applicant-tracker.onboarding.timeOffPolicies' | appTranslate }}
                <div class="sub header">
                    {{ 'forms.applicant-tracker.onboarding.assignTimeOffPoliciesToThisEmployee' | appTranslate }}
                </div>
            </h3>
            <ui-button (click)="onSelectTimeOffPolicies()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="hasPolicySelected()" class="item-list">
            <app-common-label-item
                *ngFor="let timeOffPolicy of getTimeOffPolicies()"
                (remove)="onRemoveTimeOffPolicy(timeOffPolicy)"
                [label]="timeOffPolicy.timeOffPolicy.name"
            >
            </app-common-label-item>
        </div>
    </div>

    <!-- Roles -->
    <div class="section">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.applicant-tracker.onboarding.rolesAndPermissions' | appTranslate }}
                <div class="sub header">
                    {{ 'forms.applicant-tracker.onboarding.assignRolesToThisEmployee' | appTranslate }}
                </div>
            </h3>
            <ui-button (click)="onSelectRoles()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="roles.length" class="item-list">
            <app-common-label-item
                *ngFor="let role of roles"
                (remove)="onRemoveRole(role)"
                [label]="role.name | appTranslateOptions: roleDisplayOptions"
            >
            </app-common-label-item>
        </div>
    </div>

    <!-- Training Programs -->
    <div class="section">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.applicant-tracker.onboarding.trainingPrograms' | appTranslate }}
                <div class="sub header">
                    {{ 'forms.applicant-tracker.onboarding.assignTrainingProgramsToThisEmployee' | appTranslate }}
                </div>
            </h3>
            <ui-button (click)="onSelectTrainingPrograms()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="trainingPrograms.length" class="item-list">
            <app-common-label-item
                *ngFor="let trainingProgram of trainingPrograms"
                (remove)="onRemoveTrainingPrograms(trainingProgram)"
                [label]="trainingProgram.name"
            >
            </app-common-label-item>
        </div>
    </div>

    <!-- Benefit Plan -->
    <div class="section">
        <div class="row">
            <h3 class="ui header">
                {{ benefitsHeader | appTranslate }}
                <div class="sub header">{{ benefitsDescription | appTranslate }}</div>
            </h3>
            <ui-button (click)="showBenefitDataSelectionDialog()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="benefitPlan" class="item-list">
            <app-common-label-item (remove)="onRemoveBenefitPlan(benefitPlan)" [label]="benefitPlan.name">
            </app-common-label-item>
        </div>
        <div *ngIf="simplyBenefitsData?.class" class="item-list">
            <app-common-label-item
                (remove)="onRemoveSimplyBenefitsData(simplyBenefitsData)"
                [label]="simplyBenefitsData?.class.title"
            >
            </app-common-label-item>
        </div>
    </div>

    <!-- Time Tracking -->
    <div class="section" *ngIf="showTimeTracking">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.applicant-tracker.onboarding.timeTracking' | appTranslate }}
                <span *ngIf="addToTimeTracking" class="ui label blue">{{
                    'time-tracking.hiring.added' | appTranslate
                }}</span>
                <div class="sub header">
                    {{ 'forms.applicant-tracker.onboarding.addEmployeeTimeTracking' | appTranslate }}
                </div>
            </h3>
            <ui-button (click)="onSelectTimeTracking()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.applicant-tracker.onboarding.add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="timeTrackingProjects.length" class="item-list">
            <app-common-label-item
                *ngFor="let timeTrackingProject of timeTrackingProjects"
                (remove)="onRemoveTimeTrackingProject(timeTrackingProject)"
                [label]="timeTrackingProject.name"
            >
            </app-common-label-item>
        </div>
    </div>
</form>
