<!-- Modals -->
<app-common-bulk-select-offboarding-reminder-dialog
    #selectReminders
></app-common-bulk-select-offboarding-reminder-dialog>

<!-- Create new task template modal -->
<ui-dialog
    size="small"
    [open]="showCreateTaskTemplateModal"
    (closing)="showCreateTaskTemplateModal = false"
    header="{{ 'forms.employees-terminate.addOffboardingTasks' | translate }}"
>
    <div class="create-task-template-content">
        <p>{{ 'forms.employees-terminate.noOffboardingTaskTemplatesExist' | translate }}</p>
        <p style="margin-bottom: 30px">
            {{ 'forms.employees-terminate.createOffboardingTaskTemplatesExist' | translate }}
        </p>

        <ui-button
            type="outline"
            url="/employees/task-templates/new"
            [openInNewTab]="true"
            [queryParams]="{ taskType: 'offboarding' }"
            target="_blank"
            >{{ 'forms.employees-terminate.createTaskTemplate' | translate }}
            <mat-icon svgIcon="openInNew"></mat-icon>
        </ui-button>
    </div>
</ui-dialog>

<!-- Apply task modal -->
<app-deprecated-modal-form
    #applyTasksModal
    [form]="applyTasksForm"
    [label]="'Apply'"
    (save)="onApplyTaskTemplates(applyTasksForm.getSelectedTaskTemplates())"
    headerText="{{ 'forms.employees-terminate.addOffboardingTasks' | translate }}"
>
    <app-deprecated-apply-tasks
        #applyTasksForm
        [employee]="employee"
        [autoMatchedTaskTemplates]="autoMatchedTaskTemplates"
        [otherTaskTemplates]="otherTaskTemplates"
    ></app-deprecated-apply-tasks>
</app-deprecated-modal-form>

<form class="ui form" #form="ngForm">
    <!-- Tasks -->
    <ng-container *ngIf="showOffboardingTasks">
        <div class="section">
            <div class="row">
                <h3 class="ui header">
                    {{ 'forms.employees-terminate.tasks' | translate }}
                    <div class="sub header">{{ 'forms.employees-terminate.selectOffboardingTasks' | translate }}</div>
                </h3>
                <ui-button (click)="onAddTask()" type="outline">
                    <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.employees-terminate.add' | translate }}
                </ui-button>
            </div>

            <!-- List -->
            <div *ngIf="selectedTaskTemplates.length" class="item-list">
                <app-common-label-item
                    *ngFor="let taskTemplate of selectedTaskTemplates"
                    (remove)="onRemoveTask(taskTemplate)"
                    [label]="taskTemplate.name"
                >
                </app-common-label-item>
            </div>
        </div>
    </ng-container>

    <!-- Reminders -->
    <div class="section">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.employees-terminate.reminders' | translate }}
                <div class="sub header">{{ 'forms.employees-terminate.selectOffboardingReminders' | translate }}</div>
            </h3>
            <ui-button (click)="onSelectOffboardingReminders()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'forms.employees-terminate.add' | translate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="reminders.length" class="item-list">
            <app-common-label-item
                *ngFor="let reminder of reminders"
                (remove)="onRemoveOffboardingReminder(reminder)"
                [label]="reminder.name"
            >
            </app-common-label-item>
        </div>
    </div>
</form>
